import { createGlobalStyle } from 'styled-components'
import { between, normalize } from 'polished'

import slickStyles from 'slick-carousel/slick/slick.css'
import slickTheme from 'slick-carousel/slick/slick-theme.css'
import font from '../assets/fonts/fonts.css'

const GlobalStyles = createGlobalStyle`
  ${normalize()}
  ${slickStyles}
  ${slickTheme}
  ${font}

  html {
    box-sizing: border-box;
    font-size: 100%;
    font-family: 'Hind', sans-serif;
    font-weight: 100;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  *:focus {
    outline: none;
  }

  body {
    font-size: ${between('18px', '20px', '320px', '2560px')};
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2 {
    margin: 0;
    font-size: inherit;
    font-weight: 300;
  }

  strong {
    font-weight: 700;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: all 200ms linear;
  }

  p {
    margin-top: 0;
  }

  .ReactModal__Body--open {
    overflow: hidden;
  }

  .ReactModal__Overlay {
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.75)!important;

    @media(max-height: 900px) {
      align-items: unset;
      overflow: auto!important;
    }
  }

  .ReactModal__Content {
    max-width: 1160px;
    width: auto;
    margin: auto;
    top: auto!important;
    bottom: auto!important;
    padding: ${between('30px', '60px', '320px', '2560px')}!important;
    border-radius: 0!important;
    border: 20px solid #00a0e2!important;
    opacity: 0;
    font-size: ${between('16px', '18px', '320px', '2560px')};
    overflow: hidden!important;

    @media(max-height: 900px) {
      overflow: auto!important;
    }

    h2 {
      max-width: 50vw;
      margin-bottom: 20px;
      line-height: 1.2;
      font-size: ${between('24px', '36px', '320px', '2560px')};
    }
  }

  .ReactModal__Content--after-open {
    opacity: 1;
    transition: opacity 150ms;
  }

  .ReactModal__Content--before-close {
    opacity: 0;
  }

  @media (max-width: 700px) {
    .ReactModal__Content {
      width: auto;
      left: 20px!important;
      right: 20px!important;
    }
  }
`

export default GlobalStyles
