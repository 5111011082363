import React from 'react'
import styled from 'styled-components'

const TriggerWrapper = styled.div`
  position: relative;
  display: none;
  width: 20px;
  height: 14px;
  cursor: pointer;
  z-index: 110;
  transition: all 200ms linear;

  div {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: ${props => (props.showClose ? '#fff' : '#000')};

    &:nth-child(1) {
      top: 6px;
      ${props =>
        props.showClose &&
        `
      transform: rotate(45deg);
      `}
    }

    &:nth-child(2) {
      top: 12px;
      ${props =>
        props.showClose &&
        `
      display: none;
      `}
    }

    &:nth-child(3) {
      ${props =>
        props.showClose &&
        `
        top: 6px;
      transform: rotate(-45deg);
      `}
    }
  }

  @media (max-width: 700px) {
    display: block;
  }
`

const MenuTrigger = ({ ...props }) => (
  <TriggerWrapper {...props}>
    <div />
    <div />
    <div />
  </TriggerWrapper>
)

export default styled(MenuTrigger)``
