import React from 'react'
import styled from 'styled-components'
import ReactModal from 'react-modal'

import Icon from '../images/icons/close.svg'
import IconBlue from '../images/icons/close-blue.svg'

const Close = styled.button`
  position: absolute;
  width: 15px;
  height: 15px;
  top: 30px;
  right: 30px;
  border: none;
  cursor: pointer;
  background-image: url('${Icon}');
  background-repeat: no-repeat;
  background-color: transparent;
  transition: all 200ms linear;
  z-index: 10;

  &:hover {
    background-image: url('${IconBlue}');
  }
`

const style = {
  overlay: {
    alignItems: 'center',
  },
}

const Modal = ({ children, isOpen, showClose, onClose }) => (
  <ReactModal
    ariaHideApp={false}
    isOpen={isOpen}
    onRequestClose={onClose}
    style={style}
  >
    {showClose && <Close onClick={onClose} />}
    {children}
  </ReactModal>
)

Modal.defaultProps = {
  showClose: true,
}

export default Modal
