import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import { between } from 'polished'

import Container from './Container'

const Wrapper = styled.section`
  padding: ${between('35px', '80px', '320px', '2560px')} 0;
  color: ${props => (props.variant === 'blue' ? '#fff' : '#000')};
  background-color: ${props =>
    props.variant === 'blue'
      ? '#009fe3'
      : props.variant === 'grey'
      ? '#f5f5f6'
      : '#fff'};

  font-size: ${props =>
    props.variant === 'blue'
      ? between('26px', '38px', '320px', '2560px')
      : between('18px', '20px', '320px', '2560px')};
`

const Content = styled.div`
  max-width: 735px;
  margin: auto;
  line-height: 1.2;
  text-align: ${props => (props.align === 'default' ? 'left' : props.align)};
  ${props =>
    props.size === 'large' &&
    css`
      max-width: 100%;
    `}
`

const Section = ({ children, variant, ...rest }) => (
  <Wrapper variant={variant}>
    <Container>
      <Content {...rest}>{children}</Content>
    </Container>
  </Wrapper>
)

Section.propTypes = {
  align: PropTypes.oneOf(['default', 'center', 'right']),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
  variant: PropTypes.oneOf(['default', 'blue', 'grey']),
}

Section.defaultProps = {
  align: 'center',
  variant: 'default',
}

export default Section
