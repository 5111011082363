import React, { PureComponent } from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import { between } from 'polished'
import { isAndroid, isIOS } from 'react-device-detect'

import Button from '../components/Button'
import Diagram from '../components/Diagram'
import Layout from '../components/Layout'
import Section from '../components/Section'
import ContactSection from '../components/ContactSection'
import Modal from '../components/Modal'
import StoreLogos from '../components/StoreLogos'
import { AppConsumer, withAppContext } from '../contexts/AppContext'

import WinnenderZeitung from '../images/modals/index/winnender_zeitung.jpg'
import WelzheimerZeitung from '../images/modals/index/welzheimer_zeitung.jpg'
import WaiblingerZeitung from '../images/modals/index/waiblinger_zeitung.jpg'
import SchorndorferNachrichten from '../images/modals/index/schorndorfer_nachrichten.jpg'
import QrAndroid from '../images/qrcode-android.svg'
import QrIOS from '../images/qrcode-ios.svg'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import InternalButtonLink from '../components/InternalButtonLink'

const newspapers = {
  winnenden: 'Winnender Zeitung',
  welzheim: 'Welzheimer Zeitung',
  waiblingen: 'Waiblinger Kreiszeitung',
  schorndorf: 'Schorndorfer Nachrichten',
}

const modalImages = {
  winnenden: WinnenderZeitung,
  welzheim: WelzheimerZeitung,
  waiblingen: WaiblingerZeitung,
  schorndorf: SchorndorferNachrichten,
}

const ModalWrapper = styled.div`
  display: flex;
`

const Codes = styled.div`
  display: flex;
`

const Code = styled.div`
  margin-right: ${between('50px', '100px', '320px', '2560px')};

  div {
    display: block;
    width: 100px;
  }

  img {
    width: 100%;
    height: auto;
  }
`

const ImageWrapper = styled.div`
  position: absolute;
  width: 355px;
  height: 100%;
  right: 0;
  bottom: 0;
  background-image: url('${props => modalImages[props.edition]}');
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;

  @media(max-width: 945px) {
    display: none;
  }
`

const ModalContent = styled.div`
  max-width: 55%;
  flex-grow: 1;
  flex-shrink: 0;

  @media (max-width: 945px) {
    max-width: 100%;
  }
`

class IndexPage extends PureComponent {
  state = {
    isModalOpen: false,
  }

  render() {
    return (
      <AppConsumer>
        {app => {
          return (
            <Layout>
              <Helmet>
                <title>Testen</title>
              </Helmet>

              <Section variant="blue">
                <h1>
                  {app.isPersonalized ? (
                    <>
                      {app.isCompany
                        ? `Hallo,`
                        : `Hallo ${app.purl.contact.salutation} ${
                            app.purl.contact.lastName
                          },`}
                      <br />
                      schn<strong>APP</strong>en Sie sich jetzt die{' '}
                      <strong>neue digitale Ausgabe</strong> der{' '}
                      <span style={{ whiteSpace: 'nowrap' }}>
                        {newspapers[app.edition]}
                      </span>
                      .
                    </>
                  ) : (
                    <>
                      {app.edition === 'zvw' ? (
                        <>
                          Jetzt die <strong>neue digitale Ausgabe</strong>{' '}
                          <span style={{ whiteSpace: 'nowrap' }}>
                            Ihrer Tageszeitung
                          </span>{' '}
                          schn<strong>APP</strong>en.
                        </>
                      ) : (
                        <>
                          Jetzt die <strong>neue digitale Ausgabe</strong> der{' '}
                          <span style={{ whiteSpace: 'nowrap' }}>
                            {newspapers[app.edition]}
                          </span>{' '}
                          schn<strong>APP</strong>en.
                        </>
                      )}
                    </>
                  )}
                </h1>
              </Section>

              <Diagram />

              <Section variant="grey">
                <p>
                  Schnelle Übersicht, vorab das Wichtigste wissen und viel
                  Komfort – die neue App ist die ideale Ergänzung zu Ihrer
                  täglichen Zeitungslektüre.
                </p>

                <p>
                  <strong>
                    Jetzt 14 Tage kostenlos und unverbindlich testen.
                  </strong>
                </p>

                {app.isDigitalSubscriber ? (
                  <>
                    {!isAndroid && !isIOS && (
                      <Button onClick={this.toggleModal}>
                        Jetzt downloaden
                      </Button>
                    )}

                    {isAndroid && (
                      <Button onClick={this.openPlayStore}>
                        Jetzt downloaden
                      </Button>
                    )}

                    {isIOS && (
                      <Button onClick={this.openAppStore}>
                        Jetzt downloaden
                      </Button>
                    )}
                  </>
                ) : (
                  <InternalButtonLink to="/angebote/">
                    Kostenlos testen
                  </InternalButtonLink>
                )}
              </Section>

              <ContactSection />

              <Modal isOpen={this.state.isModalOpen} onClose={this.toggleModal}>
                <ModalWrapper>
                  <ModalContent>
                    <h2>
                      Unsere <strong>neue digitale Ausgabe</strong> macht{' '}
                      <strong>APP</strong>etit – jetzt auch mit Android.
                    </h2>

                    {app.edition === 'zvw' ? (
                      <p>
                        Holen Sie sich die <strong>neue App</strong> für Ihre
                        Tageszeitung – am besten gleich heute!
                      </p>
                    ) : (
                      <p>
                        Holen Sie sich die <strong>neue App</strong> für Ihre{' '}
                        <strong>{newspapers[app.edition]}</strong> – am besten
                        gleich heute!
                      </p>
                    )}

                    <p>
                      Schnelle Übersicht, vorab das Wichtigste wissen und viel
                      Komfort – die neue App ist die ideale Ergänzung zu Ihrer
                      täglichen Zeitungslektüre. Die bisherige App wird zum 1.
                      Januar abgeschaltet und ist dann nicht mehr nutzbar.
                    </p>

                    <div>
                      <strong>Hier downloaden:</strong>
                      <StoreLogos />
                    </div>

                    <p>
                      Oder scannen Sie ganz einfach den QR-Code mit
                      <br /> Ihrem Smartphone:
                    </p>

                    <Codes>
                      <Code>
                        Android:
                        <div>
                          <img src={QrAndroid} alt="" />
                        </div>
                      </Code>
                      <Code>
                        iOS:
                        <div>
                          <img src={QrIOS} alt="" />
                        </div>
                      </Code>
                    </Codes>
                  </ModalContent>

                  <ImageWrapper edition={app.edition} />
                </ModalWrapper>
              </Modal>
            </Layout>
          )
        }}
      </AppConsumer>
    )
  }

  toggleModal = () => {
    this.setState(
      prevState => ({
        isModalOpen: !prevState.isModalOpen,
      }),
      () => {
        if (this.props.appContext.isPersonalized) {
          this.props
            .mutate({
              variables: {
                hash: this.props.appContext.purl.hash,
              },
            })
            .catch(error => console.error(error))
        }
      }
    )
  }

  openPlayStore = () => {
    if (this.props.appContext.isPersonalized) {
      this.props
        .mutate({
          variables: {
            hash: this.props.appContext.purl.hash,
          },
        })
        .catch(error => console.error(error))
    }

    window.location.href =
      'https://play.google.com/store/apps/details?id=de.msh.android.zvw&hl=de'
  }

  openAppStore = () => {
    if (this.props.appContext.isPersonalized) {
      this.props
        .mutate({
          variables: {
            hash: this.props.appContext.purl.hash,
          },
        })
        .catch(error => console.error(error))
    }

    window.location.href =
      'https://itunes.apple.com/de/app/zvw-epaper/id642946659?mt=8'
  }
}

const convertMutation = gql`
  mutation Convert($hash: String!) {
    convert(hash: $hash)
  }
`

export default graphql(convertMutation)(withAppContext(IndexPage))
