import React from 'react'
import styled from 'styled-components'

import GooglePlay from '../images/icons/google_play.svg'
import AppStore from '../images/icons/app_store.svg'

const Logos = styled.div`
  display: flex;
  margin-bottom: 30px;

  a {
    display: block;
    max-width: 150px;
    margin-right: 20px;

    &:hover {
      opacity: 0.5;
    }

    &:last-of-type {
      max-width: 135px;
      margin-right: 0;
    }
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

const StoreLogos = props => (
  <Logos {...props}>
    <a
      href="https://play.google.com/store/apps/details?id=de.msh.android.zvw&hl=de"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={GooglePlay} alt="Google Play" />
    </a>

    <a
      href="https://itunes.apple.com/de/app/zvw-epaper/id642946659?mt=8  "
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={AppStore} alt="Apple AppStore" />
    </a>
  </Logos>
)

export default styled(StoreLogos)``
