import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { between } from 'polished'

const Li = styled.li`
  margin: 0 ${between('8px', '22.5px', '320px', '2560px')};
  font-size: ${between('18px', '24px', '320px', '2560px')};

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  .testen.active {
    font-weight: 100;
  }

  & a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: #009fe3;
    }

    &.active {
      font-weight: 700;
    }
  }
`

const MenuItem = ({ children, external, to, ...rest }) => (
  <Li>
    {to ? (
      <>
        {external ? (
          <a href={to} target="_blank" rel="noopener noreferrer" {...rest}>
            {children}
          </a>
        ) : (
          <Link activeClassName="active" to={to} {...rest}>
            {children}
          </Link>
        )}
      </>
    ) : (
      <span>{children}</span>
    )}
  </Li>
)

MenuItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
  external: PropTypes.bool,
  to: PropTypes.string,
}

MenuItem.defaultProps = {
  external: false,
  to: '',
}

export default styled(MenuItem)``
