import React from 'react'
import styled from 'styled-components'

import Clock from '../images/icons/clock.svg'
import Newspaper from '../images/icons/newspaper.svg'
import Search from '../images/icons/search.svg'
import Devices from '../images/icons/devices.svg'
import Download from '../images/icons/download.svg'

const Wrapper = styled.div`
  margin: 0 20px;
  text-align: center;

  @media (max-width: 500px) {
    margin: 0;
  }
`

const IconWrapper = styled.div`
  width: 100px;
  height: 100px;
  margin: auto;

  img {
    width: 100%;
    height: 100%;
  }
`

const Hr = styled.hr`
  width: 60%;
  height: 3px;
  margin: 20px auto;
  background-color: #009fe3;
  border: none;
`

const icons = {
  clock: Clock,
  newspaper: Newspaper,
  search: Search,
  devices: Devices,
  download: Download,
}

const Feature = ({ icon, children }) => (
  <Wrapper>
    <IconWrapper>
      <img src={icons[icon]} alt="" />
    </IconWrapper>
    <Hr />
    {children}
  </Wrapper>
)

export default Feature
