import React from 'react'

import Section from './Section'
import { AppConsumer } from '../contexts/AppContext'

const ContactSection = () => (
  <AppConsumer>
    {app => (
      <Section variant="blue">
        <strong>
          {app.isPersonalized
            ? app.isCompany
              ? `Sie haben Fragen?`
              : `Sie haben Fragen ${app.purl.contact.salutation} ${
                  app.purl.contact.lastName
                }?`
            : `Sie haben Fragen?`}
        </strong>
        <br />
        <span>
          Rufen Sie uns gerne an: <strong>07151 566-444</strong>
        </span>
      </Section>
    )}
  </AppConsumer>
)

export default ContactSection
