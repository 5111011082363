import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { hsla, between } from 'polished'

import Container from './Container'
import Menu from './Menu'

const Wrapper = styled.header`
  height: ${between('100px', '140px', '320px', '2560px')};

  ${Container} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  ${Menu} {
    @media (max-width: 700px) {
      display: ${props => (props.isOpen ? 'flex' : 'none')};
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      color: #fff;
      background-color: ${hsla(198, 1, 0.45, 0.95)};
      z-index: 100;

      li {
        margin-bottom: 10px;
        font-size: ${between('32px', '45px', '320px', '700px')};
      }

      a {
        &:hover,
        &:focus {
          color: #fff !important;
          text-decoration: underline;
        }
      }

      .active {
      }
    }
  }
`

const Header = ({ children, ...rest }) => (
  <Wrapper {...rest}>
    <Container>{children}</Container>
  </Wrapper>
)

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
}

export default Header
