import React, { Fragment } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Nav from './Nav'

const Ul = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
`

const Menu = ({ children, ...rest }) => (
  <Fragment>
    <Nav>
      <Ul {...rest}>{children}</Ul>
    </Nav>
  </Fragment>
)

Menu.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
}

export default styled(Menu)``
