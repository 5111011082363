import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'

import GlobalStyles from './GlobalStyles'
import Footer from './Footer'
import Header from './Header'
import Logo from './Logo'
import Menu from './Menu'
import MenuItem from './MenuItem'
import MenuTrigger from './MenuTrigger'
import StoreLogos from './StoreLogos'

import AppleTouchIcon152 from '../images/favicons/apple-touch-icon-152x152.png'
import AppleTouchIcon144 from '../images/favicons/apple-touch-icon-144x144.png'
import MsTile from '../images/favicons/mstile-144x144.png'
import Favicon32 from '../images/favicons/favicon-32x32.png'
import Favicon16 from '../images/favicons/favicon-16x16.png'
import { AppConsumer } from '../contexts/AppContext'

const Content = styled.div`
  display: flex;

  @media (max-width: 650px) {
    flex-direction: column;
    align-items: center;
  }
`

class Layout extends Component {
  state = {
    isMenuOpen: false,
  }

  render() {
    const { children, withoutMenu } = this.props

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={(data) => (
          <Fragment>
            <Helmet titleTemplate={`%s – ${data.site.siteMetadata.title}`}>
              <html lang="de" />
              <link
                rel="apple-touch-icon-precomposed"
                sizes="144x144"
                href={AppleTouchIcon144}
              />
              <link
                rel="apple-touch-icon-precomposed"
                sizes="152x152"
                href={AppleTouchIcon152}
              />
              <link
                rel="icon"
                type="image/png"
                href={Favicon32}
                sizes="32x32"
              />
              <link
                rel="icon"
                type="image/png"
                href={Favicon16}
                sizes="16x16"
              />
              <meta name="msapplication-TileColor" content="#FFFFFF" />
              <meta name="msapplication-TileImage" content={MsTile} />
            </Helmet>

            <GlobalStyles />

            <Header isOpen={this.state.isMenuOpen}>
              <Logo />

              {!withoutMenu && (
                <>
                  <AppConsumer>
                    {(app) => {
                      return (
                        <Menu>
                          <MenuItem
                            className="testen"
                            to="/testen/"
                            onClick={this.toggleMenu}
                          >
                            Jetzt testen
                          </MenuItem>
                          <MenuItem
                            external
                            to="https://www.zvw-abo.de/"
                            onClick={this.toggleMenu}
                          >
                            Aboangebote
                          </MenuItem>
                          <MenuItem to="/kontakt/" onClick={this.toggleMenu}>
                            Kontakt
                          </MenuItem>
                          <MenuItem
                            external
                            to="http://zvw-epaper.s4p-iapps.com/"
                            onClick={this.toggleMenu}
                          >
                            Login
                          </MenuItem>
                        </Menu>
                      )
                    }}
                  </AppConsumer>

                  <MenuTrigger
                    showClose={this.state.isMenuOpen}
                    onClick={this.toggleMenu}
                  />
                </>
              )}
            </Header>

            {children}

            <Footer>
              <StoreLogos />

              <Content>
                <div>
                  <sup>*</sup>Alle Preise inkl. gesetzl. Mehrwertsteuer ggf.
                  zzgl.{' '}
                  <a
                    href="https://zvw-shop.de/versand-und-zahlung"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Versandkosten
                  </a>
                  , wenn nicht anders beschrieben.
                  <br />© {new Date().getFullYear()} Zeitungsverlag Waiblingen -
                  Alle Rechte vorbehalten
                </div>

                <Menu variant="footer">
                  <MenuItem external to="https://zvw-shop.de/agb">
                    AGB
                  </MenuItem>
                  <MenuItem external to="https://zvw-shop.de/datenschutz">
                    Datenschutz
                  </MenuItem>
                  <MenuItem external to="https://zvw-shop.de/impressum">
                    Impressum
                  </MenuItem>
                  <MenuItem external to="https://zvw-shop.de/widerruf">
                    Widerruf
                  </MenuItem>
                </Menu>
              </Content>
            </Footer>
          </Fragment>
        )}
      />
    )
  }

  toggleMenu = () => {
    this.setState((prevState) => ({
      isMenuOpen: !prevState.isMenuOpen,
    }))
  }
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
  withoutMenu: PropTypes.bool,
}

Layout.defaultProps = {
  withoutMenu: false,
}

export default Layout
