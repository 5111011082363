import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { between } from 'polished'
import { Link } from 'gatsby'

import { AppConsumer } from '../contexts/AppContext'
import WinnenderZeitung from '../images/logos/winnender-zeitung.svg'
import WelzheimerZeitung from '../images/logos/welzheimer-zeitung.svg'
import WaiblingerKreiszeitung from '../images/logos/waiblinger-kreiszeitung.svg'
import SchorndorferNachrichten from '../images/logos/schorndorfer-nachrichten.svg'

const components = {
  winnenden: {
    component: WinnenderZeitung,
    alt: 'Winnender Zeitung',
  },
  welzheim: {
    component: WelzheimerZeitung,
    alt: 'Welzheimer Zeitung',
  },
  waiblingen: {
    component: WaiblingerKreiszeitung,
    alt: 'Waiblinger Kreiszeitung',
  },
  schorndorf: {
    component: SchorndorferNachrichten,
    alt: 'Schorndorfer Nachrichten',
  },
}

const images = [
  components.waiblingen.component,
  components.schorndorf.component,
  components.winnenden.component,
  components.welzheim.component,
]

const Wrapper = styled.div`
  height: ${between('50px', '70px', '320px', '2560px')};

  & a {
    display: block;
    width: 100%;
    height: 100%;

    &:after {
      display: none;
    }
  }
`

const Image = styled.img`
  display: block;
  width: auto;
  height: 100%;
`

class Logo extends PureComponent {
  state = { imageIndex: 0 }

  changeImage = () => {
    if (this.state.imageIndex === images.length - 1) {
      this.setState({ imageIndex: 0 })
    } else {
      this.setState({ imageIndex: this.state.imageIndex + 1 })
    }
  }

  componentDidMount = () => {
    this.interval = setInterval(this.changeImage, 5000)
  }

  componentWillUnmount = () => {
    clearInterval(this.interval)
  }

  render() {
    return (
      <AppConsumer>
        {app => (
          <Wrapper>
            <Link to="/">
              {app.edition ? (
                app.edition === 'zvw' ? (
                  <Image
                    src={images[this.state.imageIndex]}
                    alt="Zeitungsverlag Waiblingen"
                  />
                ) : (
                  <Image
                    src={components[app.edition].component}
                    alt={components[app.edition].alt}
                  />
                )
              ) : (
                <></>
              )}
            </Link>
          </Wrapper>
        )}
      </AppConsumer>
    )
  }
}

export default Logo
