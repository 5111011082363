import React from 'react'
import styled from 'styled-components'
import { between, hsl } from 'polished'

import Container from './Container'
import Menu from './Menu'
import Nav from './Nav'
import StoreLogos from './StoreLogos'

const Wrapper = styled.footer`
  padding: 40px 0;
  color: #fff;
  background-color: #000;
  font-size: ${between('14px', '16px', '320px', '2560px')};

  a {
    color: #009fe3;

    &:hover {
      color: ${hsl(198, 1, 0.8)};
    }
  }

  @media (max-width: 650px) {
    text-align: center;
  }

  ${Nav} {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    flex-shrink: 0;

    @media (max-width: 650px) {
      margin-top: 30px;
    }
  }

  ${StoreLogos} {
    @media (max-width: 650px) {
      justify-content: center;
    }
  }

  ${Menu} {
    padding-left: 30px;

    li {
      flex-shrink: 0;
      margin: 0;
      font-size: inherit;

      &:after {
        margin: 0 3px;
        content: '|';
      }

      &:last-of-type {
        &:after {
          display: none;
        }
      }
    }

    & a {
      color: inherit;

      &:hover {
        color: #009fe3;
      }
    }
  }
`

const Footer = ({ children }) => (
  <Wrapper>
    <Container>{children}</Container>
  </Wrapper>
)

export default Footer
