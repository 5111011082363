import React from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'

import { AppConsumer } from '../contexts/AppContext'
import Feature from './Feature'

import WelzheimerZeitung from '../images/diagrams/diagram-welzheimer-zeitung.svg'
import WinnenderZeitung from '../images/diagrams/diagram-winnender-zeitung.svg'
import WaiblingerKreiszeitung from '../images/diagrams/diagram-waiblinger-kreiszeitung.svg'
import SchorndorferNachrichten from '../images/diagrams/diagram-schorndorfer-nachrichten.svg'

const components = {
  winnenden: WinnenderZeitung,
  welzheim: WelzheimerZeitung,
  waiblingen: WaiblingerKreiszeitung,
  schorndorf: SchorndorferNachrichten,
  zvw: WaiblingerKreiszeitung,
}

const Wrapper = styled.div`
  padding: 40px 30px;
  max-height: 880px;
  font-size: 18px;

  @media (max-width: 1030px) {
    max-height: 100%;
    padding: 35px 30px;
  }
`

const Image = styled.img`
  position: relative;
  display: block;
  max-width: 2564px;
  width: 100%;
  max-height: 963px;
  height: auto;
  pointer-events: none;

  @media (max-width: 1030px) {
    display: none;
  }
`

const Features = styled.div`
  display: none;

  .slick-slide {
    opacity: 0.3;

    &.slick-current {
      opacity: 1;
    }
  }

  .slick-list {
    margin-bottom: 30px;
  }

  .slick-dots {
    bottom: -40px;
  }

  @media (max-width: 1030px) {
    display: block;
  }

  @media (max-width: 730px) {
    .slick-slide {
      opacity: 1;
    }
  }
`

const SliderSettings = {
  dots: true,
  arrows: false,
  infinite: true,
  centerMode: true,
  slidesToShow: 3,
  centerPadding: '0px',
  responsive: [
    {
      breakpoint: 730,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

const Diagram = () => (
  <AppConsumer>
    {app => (
      <Wrapper>
        <Image src={components[app.edition]} alt="" />

        <Features>
          <Slider {...SliderSettings}>
            <Feature icon="clock">
              Sie lesen schon abends ab 18:30 Uhr, was am nächsten Morgen alle
              bewegt.
            </Feature>
            <Feature icon="newspaper">
              Sie erhalten Zugriff auf alle 4 Lokalsausgaben und Wochenblätter.
            </Feature>
            <Feature icon="devices">
              Die neue App läuft auf jedem Endgerät, sowie unter allen gängigen
              Betriebssystemen und Browsern.
            </Feature>
            <Feature icon="search">
              Sie nutzen komfortable Funktionen wie Volltextsuche und Artikel
              speichern.
            </Feature>
            <Feature icon="download">
              Sie können jede Ausgabe downloaden und dann in Ruhe lesen, auch
              ohne Internet-Verbindung.
            </Feature>
          </Slider>
        </Features>
      </Wrapper>
    )}
  </AppConsumer>
)

export default Diagram
